<!-- The world starts shaking and breaking down upon pressing a button. -->
<template>
  <b-container class="mt-5">
    <h1>Pokaziť stránku</h1>
    <div class="mt-4 mb-4">
      <p>Máš už všetkého plné zuby, chceš tresknúť dverami a niečo zničiť?</p>
      <p>
        Alebo naopak - zdá sa ti, že všetko funguje až príliš dobre a potrebuješ
        zmenu?
      </p>
      <p>Nech sa páči, môžeš si pokaziť tieto stránky, stačí kliknúť...</p>
    </div>

    <div v-if="!broken" class="text-center">
      <b-button size="lg" variant="danger" @click="start"> Pokaziť </b-button>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "BrokenMode",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      broken: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    start() {
      this.broken = true;

      const fireMaxOpacity = 0.8;
      const maxAngle = 4; // deg
      const moveInterval = 3.0; // s
      const fallingProbability = 0.002;
      const fallingDepth = "2000px";
      const shakingPeriod = "0.5s";
      const shakingDuration = 5.0; // s
      const shakingInterval = 20.0; // s

      // fire overlay
      this.$root.warningToast("Ako chceš...");
      const fire = document.createElement("img");
      fire.classList.add("fire-overlay");
      fire.src =
        "https://thumbs.gfycat.com/NeglectedLividDiamondbackrattlesnake-size_restricted.gif";
      document.querySelector("body").appendChild(fire);
      setTimeout(() => (fire.style.opacity = fireMaxOpacity), 0);

      // moving and falling elements
      setInterval(() => {
        const query =
          "*:not(html):not(body):not(.fire-overlay):not(.fallen-down)";
        for (const node of document.querySelectorAll(query)) {
          node.style.transform = `rotateZ(${(Math.random() - 0.5) * 2 * maxAngle}deg)`;
          node.style.transition = `transform ${moveInterval}s ease-in-out`;
          if (Math.random() < fallingProbability) {
            node.style.transform = `translateY(${fallingDepth})`;
            node.classList.add("fallen-down");
            setTimeout(
              () => (node.style.display = "none"),
              moveInterval * 1000,
            );
          }
        }
      }, moveInterval * 1000);

      // shaking
      setInterval(() => {
        document.querySelector("#app").style.animationDuration = shakingPeriod;
        setTimeout(() => {
          document.querySelector("#app").style.animationDuration = "0s";
        }, shakingDuration * 1000);
      }, shakingInterval * 1000);
    },
  },
};
</script>

<style>
#app {
  animation-iteration-count: infinite;
  animation-name: broken-mode-shake;
}

@keyframes broken-mode-shake {
  0% {
    transform: translate(10px, 10px) rotate(0deg);
  }
  10% {
    transform: translate(-10px, -20px) rotate(-1deg);
  }
  20% {
    transform: translate(-30px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(30px, 20px) rotate(0deg);
  }
  40% {
    transform: translate(10px, -10px) rotate(1deg);
  }
  50% {
    transform: translate(-10px, 20px) rotate(-1deg);
  }
  60% {
    transform: translate(-30px, 10px) rotate(0deg);
  }
  70% {
    transform: translate(30px, 10px) rotate(-1deg);
  }
  80% {
    transform: translate(-10px, -10px) rotate(1deg);
  }
  90% {
    transform: translate(10px, 20px) rotate(0deg);
  }
  100% {
    transform: translate(10px, -20px) rotate(-1deg);
  }
}

.fire-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 900s;
}
</style>
